import React, { useEffect, useState } from 'react';

import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from './CheckoutForm';
import { loadStripe } from '@stripe/stripe-js';

const Checkout = () => {
    const [stripePromise, setStripePromise] = useState(null);

    useEffect(() => {
        const publishableKey = sessionStorage.getItem('publishableKey');
        
        if (publishableKey) {
            setStripePromise(loadStripe(publishableKey));
        } else {
            console.error('Publishable key not found in sessionStorage');
        }
    }, []);

    if (!stripePromise) {
        return <div>Loading...</div>;
    }

    return (
        <Elements stripe={stripePromise}>
            <CheckoutForm />
        </Elements>
    );
};

export default Checkout;
