import "./App.css";
import "./output.css";
import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { AuthProvider } from "./services/AuthContext";
import PrivateRoute from "./services/PrivateRoute";
import LoginPage from "./pages/LoginPage";
import DashboardPage from "./pages/DashboardPage";
import AboutPage from "./pages/AboutPage";
import Checkout from "./pages/Checkout";
// import LocationPage from "./pages/Location";

function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route
            path="/dashboard"
            element={<PrivateRoute element={<DashboardPage />} />}
          />
          <Route
            path="/info"
            element={<PrivateRoute element={<AboutPage />} />}
          />
          <Route
            path="/checkout"
            element={<PrivateRoute element={<Checkout />} />}
          />
          {/* <Route
            path="/location"
            element={<PrivateRoute element={<LocationPage />} />}
          /> */}
          {/* Add a default route */}
          <Route path="/" element={<Navigate to="/dashboard" replace />} />
          {/* Catch-all route to redirect to dashboard */}
          <Route path="*" element={<Navigate to="/dashboard" replace />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
