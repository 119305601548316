import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';

const CreditCardIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
        <rect x="1" y="4" width="22" height="16" rx="2" ry="2"></rect>
        <line x1="1" y1="10" x2="23" y2="10"></line>
    </svg>
);

const CheckIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
        <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
        <polyline points="22 4 12 14.01 9 11.01"></polyline>
    </svg>
);

const ErrorIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
        <circle cx="12" cy="12" r="10"></circle>
        <line x1="12" y1="8" x2="12" y2="12"></line>
        <line x1="12" y1="16" x2="12.01" y2="16"></line>
    </svg>
);

const CheckoutForm = () => {
    const [error, setError] = useState(null);
    const [isProcessing, setIsProcessing] = useState(false);
    const [paymentSuccess, setPaymentSuccess] = useState(false);
    const stripe = useStripe();
    const elements = useElements();
    const location = useLocation();
    const navigate = useNavigate();
    const { logId } = location.state || {};

    // Retrieve values from sessionStorage
    const amount = sessionStorage.getItem('amount');
    const currency = sessionStorage.getItem('currency');
    const conveniencefee = sessionStorage.getItem('conveniencefee');
    const totalamount = sessionStorage.getItem('totalamount');
    const email = sessionStorage.getItem('email');

    useEffect(() => {
        console.log("Amount:", amount, "Currency:", currency, "Convenience Fee:", conveniencefee, "Total Amount:", totalamount, "Email:", email);
    }, [amount, currency, conveniencefee, totalamount,email]);

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        setIsProcessing(true);

        const cardElement = elements.getElement(CardElement);

        const { error, token } = await stripe.createToken(cardElement);

        if (error) {
            console.error(error);
            setError(error.message || 'An unknown error occurred');
            setIsProcessing(false);
            return;
        }

        setError(null);

        const currentUser = localStorage.getItem("current user");
        const ld_token = currentUser ? JSON.parse(currentUser).token : null;
        const userAgent = navigator.userAgent;

        const payload = {
            StripeToken: token.id,
            StripeEmail: email,
            LogID: logId,
            UserAgent: userAgent
        };

        try {
            const response = await fetch('https://windev.mashvirtual.in/ZyncSmartSchedular/api/Payment/PaymentReturn', {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    "Accept-Encoding": "gzip, deflate, br",
                    Accept: "*/*",
                    Authorization: `Bearer ${ld_token}`,
                },
                body: JSON.stringify(payload),
            });

            const data = await response.json();

            if (response.ok) {
                setPaymentSuccess(true);
                setTimeout(() => {
                    navigate('/dashboard');
                }, 2000);
            } else {
                setError(data.message || 'Payment failed');
            }
        } catch (error) {
            console.error('Error during payment:', error);
            setError('An error occurred during payment');
        }

        setIsProcessing(false);
    };

    return (
        <div className="min-h-screen bg-gradient-to-br from-[#F9E9DE] to-[#FFD1BA] flex items-center justify-center p-4">
            <div className="w-full max-w-md bg-white shadow-2xl rounded-2xl overflow-hidden transform transition-all duration-300 hover:scale-[1.02]">
                <div className="bg-[#1E73BE] p-6 text-white flex items-center">
                    <CreditCardIcon />
                    <h2 className="text-2xl font-bold ml-3">Payment Details</h2>
                </div>
                
                <form 
                    onSubmit={handleSubmit} 
                    className="p-6 space-y-6"
                >
                    {/* Payment Information Section */}
                    <div className="bg-gray-100 rounded-lg p-4 space-y-2">
                        <div className="flex justify-between items-center border-b pb-2">
                            <span className="text-gray-600 font-medium">Currency</span>
                            <span className="font-bold text-gray-800 uppercase">{currency}</span>
                        </div>
                        <div className="flex justify-between items-center border-b pb-2">
                            <span className="text-gray-600 font-medium">Amount</span>
                            <span className="font-bold text-gray-800">{amount}</span>
                        </div>
                        <div className="flex justify-between items-center border-b pb-2">
                            <span className="text-gray-600 font-medium">Convenience Fee</span>
                            <span className="font-bold text-gray-800">{conveniencefee}</span>
                        </div>
                        <div className="flex justify-between items-center">
                            <span className="text-gray-600 font-medium">Total Amount</span>
                            <span className="font-bold text-green-600">{totalamount}</span>
                        </div>
                    </div>

                    {/* Card Details Section */}
                    <div className="space-y-4">
                        <label className="block text-gray-700 font-semibold">
                            Card Details
                        </label>
                        <div className="border border-gray-300 p-3 rounded-lg hover:border-[#FF6B6B] transition-all duration-300">
                            <CardElement
                                options={{
                                    style: {
                                        base: {
                                            fontSize: '16px',
                                            color: '#424770',
                                            '::placeholder': {
                                                color: '#aab7c4',
                                            },
                                        },
                                        invalid: {
                                            color: '#9e2146',
                                        },
                                    },
                                }}
                            />
                        </div>
                    </div>

                    {/* Error Handling */}
                    {error && (
                        <div className="flex items-center bg-red-50 border border-red-200 text-red-700 p-3 rounded-lg space-x-3">
                            <ErrorIcon />
                            <p className="text-sm">{error}</p>
                        </div>
                    )}

                    {/* Submit Button */}
                    <button
                        type="submit"
                        disabled={!stripe || isProcessing}
                        className="w-full bg-[#1E73BE] text-white py-3 rounded-lg 
                        hover:opacity-90 transition-all duration-300 
                        disabled:opacity-50 disabled:cursor-not-allowed 
                        flex items-center justify-center space-x-2"
                    >
                        {isProcessing ? (
                            <span className="animate-pulse">Processing...</span>
                        ) : (
                            'Pay Now'
                        )}
                    </button>

                    {/* Success Message */}
                    {paymentSuccess && (
                        <div className="flex items-center bg-green-50 border border-green-200 text-green-700 p-3 rounded-lg space-x-3">
                            <CheckIcon />
                            <p className="text-sm">Appointment Booked Successfully!</p>
                        </div>
                    )}
                </form>
            </div>
        </div>
    );
};

export default CheckoutForm;