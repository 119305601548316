//About Page with const data
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  TextField, Box, FormControl, InputLabel, Select, MenuItem, FormGroup, Modal,
  FormControlLabel, FormHelperText, Checkbox, Button, InputAdornment, Card,
  CardContent, Typography, IconButton, Grid,
} from "@mui/material";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import ConfirmationNumberOutlinedIcon from "@mui/icons-material/ConfirmationNumberOutlined";
import PhoneIphoneOutlinedIcon from "@mui/icons-material/PhoneIphoneOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import LocationCityOutlinedIcon from "@mui/icons-material/LocationCityOutlined";
import NearMeOutlinedIcon from "@mui/icons-material/NearMeOutlined";
import Slider from "react-slick";
import { LocationOn, CalendarToday, Directions, AccessTime, ArrowBack, ArrowForward } from "@mui/icons-material";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";



function AboutPage() {
  const navigate = useNavigate();
  const [location, setLocation] = useState([]);

  const LocationCard = ({
    location,
    isSelected,
    onSelect,
    variant = "default",
  }) => (
    <Card
      className={`mb-4 relative overflow-hidden ${isSelected ? "ring-2 ring-blue-500 ring-offset-2" : "hover:shadow-xl"
        } ${variant === "featured"
          ? "bg-gradient-to-br from-blue-50 to-blue-100 border-blue-200"
          : "bg-gradient-to-b from-white to-gray-50"
        }`}
    >
      <CardContent className="p-6">
        <div
          className={`absolute top-0 right-0 w-32 h-32 -mr-16 -mt-16 rounded-full blur-2xl ${variant === "featured" ? "bg-blue-600/20" : "bg-blue-500/10"
            }`}
        ></div>

        <Typography
          variant="h6"
          component="div"
          className="flex items-center mb-4 relative z-10"
        >
          <LocationOn
            className={`mr-2 ${variant === "featured" ? "text-blue-700" : "text-blue-600"
              }`}
          />
          <span
            className={`font-semibold ${variant === "featured" ? "text-blue-900" : "text-gray-800"
              }`}
          >
            {location?.name}
          </span>
        </Typography>

        <div className="space-y-3 relative z-10">
          <Typography
            variant="body2"
            className={
              variant === "featured" ? "text-blue-900/80" : "text-gray-600"
            }
          >
            {location?.address}
          </Typography>

          <div
            className={`flex items-center ${variant === "featured" ? "text-emerald-700" : "text-emerald-600"
              }`}
          >
            <Directions className="mr-2 h-5 w-5" />
            <span className="font-medium">{location?.distance} miles away</span>
          </div>

          <div
            className={`flex items-center ${variant === "featured" ? "text-blue-700" : "text-blue-600"
              }`}
          >
            <CalendarToday className="mr-2 h-5 w-5" />
            <span className="font-medium">{location?.nextAvailableDate}</span>
          </div>
        </div>

        <div className="mt-6 flex gap-3">
          <Button
            variant="contained"
            href={location?.mapUrl}
            target="_blank"
            className={`flex-1 ${variant === "featured"
              ? "bg-[#1E73BE]"
              : "bg-[#1E73BE]"
              }`}
            style={{
              borderRadius: "12px",
              textTransform: "none",
              padding: "10px 20px",
              boxShadow: "0 4px 6px -1px rgb(0 0 0 / 0.1)",
            }}
          >
            View on Map
          </Button>

          <Button
            variant="outlined"
            onClick={onSelect}
            className="flex-1"
            style={{
              borderRadius: "12px",
              textTransform: "none",
              padding: "10px 20px",
              borderColor: variant === "featured" ? "#1e40af" : "#3b82f6",
              color: variant === "featured" ? "#1e40af" : "#3b82f6",
            }}
          >
            {isSelected ? "Selected" : "Select"}
          </Button>
        </div>
      </CardContent>
    </Card>
  );
  const AvailabilityCarousel = ({ availability }) => {
    const [selectedDate, setSelectedDate] = useState(null);
    const [slidesToShow, setSlidesToShow] = useState({
      dates: 3,
      times: 4,
    });
    useEffect(() => {
      const handleResize = () => {
        if (window.innerWidth < 640) {
          // sm breakpoint
          setSlidesToShow({
            dates: 1,
            times: 1,
          });
        } else if (window.innerWidth < 768) {
          // md breakpoint
          setSlidesToShow({
            dates: 2,
            times: 2,
          });
        } else {
          setSlidesToShow({
            dates: 3,
            times: 4,
          });
        }
      };

      handleResize(); // Initial check
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, []);

    const dateSettings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      responsive: [
        {
          breakpoint: 640,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
      ],
    };

    const timeSettings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      responsive: [
        {
          breakpoint: 640,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
      ],
    };

    return (
      <div className="mt-12 bg-white rounded-2xl p-6 shadow-lg">
        <Typography
          variant="h5"
          component="h3"
          className="font-bold mb-6 text-gray-800"
        >
          Available Dates
        </Typography>

        <div className="mx-8">
          <Slider {...dateSettings}>
            {availability.locationAvailabilityDates.map((date) => (
              <div key={date.formattedAvailabilityDate} className="px-2">
                <Button
                  variant={selectedDate === date ? "contained" : "outlined"}
                  onClick={() => setSelectedDate(date)}
                  className="w-full"
                  style={{
                    borderRadius: "12px",
                    padding: "12px",
                    textTransform: "none",
                    backgroundColor:
                      selectedDate === date ? "#1E73BE" : "transparent",
                    borderColor: "#1E73BE",
                    color: selectedDate === date ? "white" : "#1E73BE",
                  }}
                >
                  <div className="flex flex-col items-center">
                    <span className="font-medium">
                      {date.formattedAvailabilityDate}
                    </span>
                    <span className="font-medium">
                      {date.dayOfWeek}
                    </span>
                    <span className="text-sm opacity-80">{date.DayOfWeek}</span>
                  </div>
                </Button>
              </div>
            ))}
          </Slider>
        </div>

        {selectedDate && (
          <div className="mt-10">
            <Typography
              variant="h5"
              component="h3"
              className="font-bold mb-6 text-gray-800"
            >
              Available Time Slots
            </Typography>

            <div className="mx-8">
              <Slider {...timeSettings}>
                {selectedDate.availableTimeSlots.map((slot) => (
                  <div key={slot.SlotId} className="px-2">
                    <Button
                      variant="outlined"
                      className="w-full"
                      style={{
                        borderRadius: "12px",
                        padding: "12px",
                        textTransform: "none",
                        borderColor: "#3b82f6",
                        color: "#3b82f6",
                      }}
                    >
                      <div className="flex items-center justify-center">
                        <AccessTime className="mr-2 h-5 w-5" />
                        <span className="font-medium">
                          {slot.formattedTime}
                        </span>
                      </div>
                    </Button>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        )}
      </div>
    );
  };

  const NextArrow = (props) => {
    const { onClick } = props;

    return (
      <IconButton
        class="hover-transparent"
        // className={`${className} hover-transparent`}
        style={{
          display: "block",
          right: "-40px",
          top: "50%",
          transform: "translateY(-50%)",
          zIndex: 1,
          width: "40px",
          height: "40px",
          borderRadius: "50%",
          position: "absolute",
        }}
        onClick={onClick}
      >
        <ArrowForward className="text-blue-600" />
      </IconButton>
    );
  };

  const PrevArrow = (props) => {
    const { onClick } = props;
    return (
      <IconButton
        class="hover-transparent"
        // className={`${className} !bg-white !shadow-lg`}
        style={{
          display: "block",
          left: "-40px",
          top: "50%",
          transform: "translateY(-50%)",
          zIndex: 1,
          width: "40px",
          height: "40px",
          borderRadius: "50%",
          position: "absolute",
        }}
        onClick={onClick}
      >
        <ArrowBack className="text-blue-600" />
      </IconButton>
    );
  };


  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    dob: "",
    homephoneno: "",
    cellphoneno: "",
    email: "",
    zipCode: "",
    city: "",
    appointmentdays: [0],
    isspanishnotify: false,
    isadaaccomodation: false,
    isnotificationmessage: false,
    isReadTnC: false,
  });

  const TermsAndConditionsModal = ({ open, handleClose }) => (
    <Modal open={open} onClose={handleClose}>
      <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', boxShadow: 24, p: 4 }}>
        <Typography variant="h6" component="h2">
          Terms and Conditions
        </Typography>
        <Typography sx={{ mt: 2 }}>
          Here are the terms and conditions...
        </Typography>
        <Button onClick={handleClose} sx={{ mt: 2 }}>Close</Button>
      </Box>
    </Modal>
  );
  //
  const [formErrors, setFormErrors] = useState({});
  //
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  // 

  useEffect(() => {
    const fetchAppointmentInfo = async () => {
      try {
        const currentUser = localStorage.getItem("current user");
        const token = currentUser ? JSON.parse(currentUser).token : null;
        const response = await fetch(
          "https://windev.mashvirtual.in/ZyncSmartSchedular/api/Account/GetAppointmentInfo",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              UserAgent: navigator.userAgent,
            }),
          }
        );

        if (!response.ok) {
          throw new Error(`Error: ${response.status} ${response.statusText}`);
        }

        const result = await response.json();
        const dateOfBirth = result.data.dob;
        const formattedDob = dateOfBirth ? new Date(dateOfBirth) : null;
        const formattedDate = formattedDob
          ? `${formattedDob.getMonth() + 1
          }/${formattedDob.getDate()}/${formattedDob.getFullYear()}`
          : "";
        setFormData((prevState) => ({
          ...prevState,
          firstname: result.data.firstname || "",
          lastname: result.data.lastname || "",
          dob: formattedDate || "",
          email: result.data.email || "",
          cellphoneno: result.data.cellphoneno || "",
          homephoneno: result.data.homephoneno || "",
        }));
      } catch (error) {
        console.error("API call failed:", error);
      }
    };

    fetchAppointmentInfo();
  }, []);

  // 
  const validateForm = () => {
    const errors = {};
    const phoneRegex = /^\d{1,11}$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const zipCodeRegex = /^\d{5}$/;
    const cityRegex = /^[a-zA-Z\s]+$/;

    if (formData.homephoneno && !phoneRegex.test(formData.homephoneno)) {
      errors.homephoneno = "Home phone must be digits only and max 11 digits.";
    }

    if (!phoneRegex.test(formData.cellphoneno)) {
      errors.cellphoneno = "Cell phone must be digits only and max 11 digits.";
    }

    if (!emailRegex.test(formData.email)) {
      errors.email = "Invalid email format.";
    }

    if (formData.zipCode && !zipCodeRegex.test(formData.zipCode)) {
      errors.zipCode = "Zip code must be exactly 5 digits.";
    }

    if (formData.city && !cityRegex.test(formData.city.trim())) {
      errors.city = "City must contain only alphabets and whitespaces.";
    }

    if (!formData.zipCode && !formData.city.trim()) {
      errors.zipCode = "Either Zip Code or City must be provided.";
      errors.city = "Either Zip Code or City must be provided.";
    }
    if (!formData.isReadTnC) {
      errors.isReadTnC = "Please checked this box";
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };
  // 
  const [isModalOpen, setModalOpen] = useState(false);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
    if (name === "appointmentdays") {
      setFormData((prevState) => ({
        ...prevState,
        [name]: [value],
      }));
    }
    if (name === 'isReadTnC' && checked) {
      setModalOpen(true);
    }
  };

  const handleCreateAppointment = async (e) => {

    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    const dateParts = formData.dob.split("/");
    const formattedSubmitDate = `${dateParts[2]}-${dateParts[0]}-${dateParts[1]}`;

    const updatedFormData = {
      ...formData,
      dob: formattedSubmitDate,
      UserAgent: navigator.userAgent,
    };

    const currentUser = localStorage.getItem("current user");
    const token = currentUser ? JSON.parse(currentUser).token : null;

    if (currentUser) {
      const currentUserObj = JSON.parse(currentUser);
      if (currentUserObj.txdpsToken) {
        updatedFormData.txdpsToken = currentUserObj.txdpsToken;
      }
    }
    // console.log(updatedFormData.email)
    sessionStorage.setItem('email', updatedFormData.email);
    try {
      const response = await fetch(
        "https://windev.mashvirtual.in/ZyncSmartSchedular/api/Account/CreateAppointmentInfo",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Accept-Encoding": "gzip, deflate, br",
            Accept: "*/*",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(updatedFormData),
        }
      );


      if (!response.ok)
        throw new Error(`Error: ${response.status} ${response.statusText}`);
      setIsFormSubmitted(true);

      // Call the GetAvailableLocation API
      const locationResponse = await fetch(

        "https://windev.mashvirtual.in/ZyncSmartSchedular/api/Appointment/GetAvailableLocation",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            CityName: formData.city,
            // PreferredDay: formData.appointmentdays[''],
            PreferredDay: 0,
            TypeId: 81,
            ZipCode: formData.zipCode,
            UserAgent: navigator.userAgent,
          }),
        }
      );

      if (!locationResponse.ok)
        throw new Error(
          `Error: ${locationResponse.status} ${locationResponse.statusText}`
        );

      const locationResult = await locationResponse.json();
      // console.log("locationResult ", locationResult.data);


      if (locationResult && locationResult.data.length > 0) {
        setLocation(locationResult.data);

        setSelectedLocation(locationResult.data[0]);
      }
    } catch (error) {
      console.error("dev console API call failed:", error);
    }
  };
  // 
  const getAvaiableDates = async (passedLocationId) => {
    const updatedFormData = {
      LocationId: passedLocationId,
      PreferredDay: 0,
      SameDay: false,
      StartDate: null,
      TypeId: 81,
      UserAgent: navigator.userAgent,
    };

    const currentUser = localStorage.getItem("current user");
    const token = currentUser ? JSON.parse(currentUser).token : null;

    try {
      const response = await fetch(
        "https://windev.mashvirtual.in/ZyncSmartSchedular/api/Appointment/GetAvailableLocationDates",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Accept-Encoding": "gzip, deflate, br",
            Accept: "*/*",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(updatedFormData),
        }
      );

      if (!response.ok)
        throw new Error(`Error: ${response.status} ${response.statusText}`);
      setIsFormSubmitted(true);
      const result = await response.json();
      
      // console.log("response ", result.data);
    } catch (error) {
      console.error("dev console API call failed:", error);
    }
  };
  //location
  const [selectedLocation, setSelectedLocation] = useState(location[0]);

  const handleLogout = () => {
    localStorage.removeItem("current user");
    navigate("/login");
    window.location.reload();
  };


  const SaveLocation = async () => {

    const currentUser = localStorage.getItem("current user");
    const token = currentUser ? JSON.parse(currentUser).token : null;
    const url = "https://windev.mashvirtual.in/ZyncSmartSchedular/api/Appointment/SelectLocation";
    const userAgent = navigator.userAgent;

    try {
      const locationResponse = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Accept-Encoding": "gzip, deflate, br",
          Accept: "/",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          UserAgent: userAgent,
          SiteId: selectedLocation ? Number(selectedLocation.id) : undefined,
        }),
      });

      if (!locationResponse.ok) {
        throw new Error("Network response was not ok");
      }

      const locationData = await locationResponse.json();
      // console.log("Success Location Saved Successfull:", locationData.data.selectedSiteId);

      // Initiate payment
      const paymentInitiateResponse = await fetch("https://windev.mashvirtual.in/ZyncSmartSchedular/api/Payment/InitiatePayment", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Accept-Encoding": "gzip, deflate, br",
          Accept: "/",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          SelectedSiteId: locationData.data.selectedSiteId,
          UserAgent: userAgent,
        }),
      });

      if (!paymentInitiateResponse.ok) {
        throw new Error("Network response was not ok");
      }

      const InitiatePaymentData = await paymentInitiateResponse.json();
      // console.log("InitiatePayment Res ID:", InitiatePaymentData.data.logID);
      // console.log("Publishable Key:", InitiatePaymentData.data.publishableKey);

      sessionStorage.setItem('publishableKey', InitiatePaymentData.data.publishableKey);
      sessionStorage.setItem('amount', InitiatePaymentData.data.amount);
      sessionStorage.setItem('conveniencefee', InitiatePaymentData.data.conveniencefee);
      sessionStorage.setItem('totalamount', InitiatePaymentData.data.totalamount);
      sessionStorage.setItem('currency', InitiatePaymentData.data.currency);

      const logId = InitiatePaymentData.data.logID;
      navigate('/checkout', { state: { logId } });
    }

    catch (error) {
      console.error("Error:", error);
    }
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };


  return (
    <div>
      <header className="px-6 py-4 flex justify-between items-center border-b border-gray-200">
        <div className="flex items-center">
          <h1 className="text-2xl font-bold">Zync Smart Scheduler</h1>
        </div>
        <button
          className="bg-[#1E73BE] text-white px-4 py-2 rounded"
          onClick={handleLogout}
        >
          Logout
        </button>
      </header>

      <div className="bg-[#F9E9DE] min-h-screen flex flex-col items-center p-4">
        <main className="w-full max-w-3xl bg-white p-6 rounded-lg shadow-md">
          {!isFormSubmitted ? (
            <form
              className="space-y-3"
              noValidate
              autoComplete="off"
              onSubmit={handleCreateAppointment}
            >
              <h1 className="text-2xl font-bold mb-6 text-center">
                Customer Details
              </h1>
              <Box
                sx={{
                  display: "grid",
                  gridGap: 20,
                }}
              >
                <TextField
                  label="First Name"
                  size="small"
                  name="firstname"
                  value={formData.firstname}
                  onChange={handleInputChange}
                  required
                  sx={{ m: 1 }}
                  slotProps={{
                    input: {
                      readOnly: true,
                      startAdornment: (
                        <InputAdornment position="start">
                          {
                            <ConfirmationNumberOutlinedIcon
                              sx={{ color: "#1E73BE" }}
                            />
                          }
                        </InputAdornment>
                      ),
                    },
                  }}
                />
                <TextField
                  label="Last Name"
                  size="small"
                  name="lastname"
                  value={formData.lastname}
                  onChange={handleInputChange}
                  required
                  sx={{ m: 1 }}
                  slotProps={{
                    input: {
                      readOnly: true,
                      startAdornment: (
                        <InputAdornment position="start">
                          {
                            <ConfirmationNumberOutlinedIcon
                              sx={{ color: "#1E73BE" }}
                            />
                          }
                        </InputAdornment>
                      ),
                    },
                  }}
                />
                <TextField
                  label="Date of Birth"
                  size="small"
                  name="dob"
                  value={formData.dob}
                  onChange={handleInputChange}
                  sx={{ m: 1 }}
                  slotProps={{
                    input: {
                      readOnly: true,
                      startAdornment: (
                        <InputAdornment position="start">
                          {
                            <CalendarMonthOutlinedIcon
                              sx={{ color: "#1E73BE" }}
                            />
                          }
                        </InputAdornment>
                      ),
                    },
                  }}
                  required
                />
                <TextField
                  label="Home Phone"
                  size="small"
                  name="homephoneno"
                  value={formData.homephoneno}
                  onChange={handleInputChange}
                  error={!!formErrors.homephoneno}
                  helperText={formErrors.homephoneno}
                  sx={{ m: 1 }}
                  slotProps={{
                    input: {
                      startAdornment: (
                        <InputAdornment position="start">
                          {
                            <PhoneIphoneOutlinedIcon
                              sx={{ color: "#1E73BE" }}
                            />
                          }
                        </InputAdornment>
                      ),
                    },
                  }}
                />
                <TextField
                  label="Cell Phone"
                  size="small"
                  name="cellphoneno"
                  value={formData.cellphoneno}
                  onChange={handleInputChange}
                  required
                  sx={{ m: 1 }}
                  error={!!formErrors.cellphoneno}
                  helperText={formErrors.cellphoneno}
                  slotProps={{
                    input: {
                      startAdornment: (
                        <InputAdornment position="start">
                          {
                            <PhoneIphoneOutlinedIcon
                              sx={{ color: "#1E73BE" }}
                            />
                          }
                        </InputAdornment>
                      ),
                    },
                  }}
                />
                <TextField
                  label="Email"
                  size="small"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  error={!!formErrors.email}
                  helperText={formErrors.email}
                  required
                  sx={{ m: 1 }}
                  slotProps={{
                    input: {
                      startAdornment: (
                        <InputAdornment position="start">
                          {<EmailOutlinedIcon sx={{ color: "#1E73BE" }} />}
                        </InputAdornment>
                      ),
                    },
                  }}
                />
                <TextField
                  label="Zip Code"
                  size="small"
                  name="zipCode"
                  value={formData.zipCode}
                  onChange={handleInputChange}
                  error={!!formErrors.zipCode}
                  helperText={formErrors.zipCode}
                  required
                  sx={{ m: 1 }}
                  slotProps={{
                    input: {
                      startAdornment: (
                        <InputAdornment position="start">
                          {<NearMeOutlinedIcon sx={{ color: "#1E73BE" }} />}
                        </InputAdornment>
                      ),
                    },
                  }}
                />
                <TextField
                  label="City"
                  size="small"
                  name="city"
                  value={formData.city}
                  onChange={handleInputChange}
                  required
                  error={!!formErrors.city}
                  helperText={formErrors.city}
                  sx={{ m: 1 }}
                  slotProps={{
                    input: {
                      startAdornment: (
                        <InputAdornment position="start">
                          {
                            <LocationCityOutlinedIcon
                              sx={{ color: "#1E73BE" }}
                            />
                          }
                        </InputAdornment>
                      ),
                    },
                  }}
                />
                <FormControl required sx={{ m: 1 }} size="small">
                  <InputLabel id="day-select-label" className="bg-white px-2" >
                    Day
                  </InputLabel>
                  <Select
                    labelId="day-select-label"
                    name="appointmentdays"
                    value={formData.appointmentdays}
                    onChange={handleInputChange}
                  >
                    <MenuItem value={0}>Any Day</MenuItem>
                    <MenuItem value={1}>Monday</MenuItem>
                    <MenuItem value={2}>Tuesday</MenuItem>
                    <MenuItem value={3}>Wednesday</MenuItem>
                    <MenuItem value={4}>Thursday</MenuItem>
                    <MenuItem value={5}>Friday</MenuItem>
                    <MenuItem value={6}>Saturday</MenuItem>
                    <MenuItem value={7}>Sunday</MenuItem>
                  </Select>
                </FormControl>
                <FormGroup sx={{ m: 1 }} size="small">
                  <FormControlLabel
                    style={{ display: "none" }}
                    control={
                      <Checkbox
                        checked={formData.isspanishnotify}
                        onChange={handleInputChange}
                        name="isspanishnotify"
                      />
                    }
                    label="Receive notifications in Spanish"
                  />
                  <FormControlLabel
                    style={{ display: "none" }}
                    control={
                      <Checkbox
                        checked={formData.isadaaccomodation}
                        onChange={handleInputChange}
                        name="isadaaccomodation"
                      />
                    }
                    label="Need ADA accommodation"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formData.isnotificationmessage}
                        onChange={handleInputChange}
                        name="isnotificationmessage"
                      />
                    }
                    label="Receive notifications via text message."
                  />
                  <FormControl error={!!formErrors.isReadTnC}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formData.isReadTnC}
                          onChange={handleInputChange}
                          name="isReadTnC"
                          required
                        />
                      }
                      label={
                        <span>
                          I have read the <span style={{ color: 'blue' }}>terms and conditions</span>.
                        </span>
                      }
                    />
                    {formErrors.isReadTnC && (
                      <FormHelperText>{formErrors.isReadTnC}</FormHelperText>
                    )}
                  </FormControl>
                  <TermsAndConditionsModal open={isModalOpen} handleClose={handleCloseModal} />
                </FormGroup>
              </Box>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                sx={{ mt: 2 }}
              >
                Submit
              </Button>
            </form>
          ) : (
            <Box sx={{ mt: 3 }}>
              {/* Selected Location Section */}
              <div className="mb-5 bg-gradient-to-br from-blue-600/10 to-blue-100/30 p-8 rounded-2xl shadow-lg">
                <Typography
                  variant="h5"
                  component="h2"
                  className="font-bold mb-8 text-blue-900 flex items-center"
                >
                  <LocationOn className="mr-2" />
                  Selected Location
                </Typography>

                <LocationCard
                  location={selectedLocation}
                  isSelected={true}
                  variant="featured"
                />
              </div>

              {/* Suggested Locations Section */}
              <div className="bg-white p-8 rounded-2xl shadow-lg">
                <Typography
                  variant="h5"
                  component="h2"
                  className="font-bold mb-8 text-gray-800 flex items-center"
                >
                  <LocationOn className="mr-2 text-gray-600" />
                  Other Suggested Locations
                </Typography>

                <Grid container spacing={3}>
                  {location.map((curLocation) => (
                    <Grid item xs={12} key={curLocation?.id}>
                      <LocationCard
                        location={curLocation}
                        isSelected={selectedLocation?.id === curLocation?.id}
                        onSelect={() => {
                          getAvaiableDates(curLocation?.id);
                          setSelectedLocation(curLocation);
                        }}
                      />
                    </Grid>
                  ))}
                </Grid>
              </div>

              {/* Availability Section */}
              {selectedLocation && selectedLocation?.availability && (
                <AvailabilityCarousel
                  availability={selectedLocation?.availability}
                />
              )}
              <div className="mt-2 flex flex-col items-center justify-center">
                <button className="bg-[#1E73BE] text-white px-4 py-2 rounded" onClick={SaveLocation}>
                  Save
                </button>
              </div>
            </Box>
          )}
        </main>
      </div>
    </div>
  );
}

export default AboutPage;