import ReCAPTCHA from 'react-google-recaptcha';
import React, { useState, useEffect, useRef } from "react";
import { TextField, Box, Snackbar, Alert } from "@mui/material";
import { useNavigate } from "react-router-dom";
import InputAdornment from "@mui/material/InputAdornment";
import BadgeOutlinedIcon from "@mui/icons-material/BadgeOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import ConfirmationNumberOutlinedIcon from "@mui/icons-material/ConfirmationNumberOutlined";
import { useAuth } from "../services/AuthContext";

function LoginPage() {

  const [form, setForm] = useState({
    cardNo: '',
    firstName: '',
    lastName: '',
    ssn: '',
    dob: '',
    confirmationNo: '',
    recaptchaToken: '',
    userAgent: navigator.userAgent,
  });

  const [touched, setTouched] = useState({});
  const [dirty, setDirty] = useState({});
  const [errors, setErrors] = useState({});
  const recaptchaRef = useRef(null);
  // const login = useAuth();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  useEffect(() => {
    const user = localStorage.getItem("current user");
    if (user) {
      navigate("/dashboard");
    }
  }, [navigate]);

  const validate = () => {
    const newErrors = {};
    if (!/^[a-zA-Z]+$/.test(form.firstName)) {
      newErrors.firstName = 'First name should contain only alphabets';
    }
    if (!/^[a-zA-Z]+$/.test(form.lastName)) {
      newErrors.lastName = 'Last name should contain only alphabets';
    }
    if (!/^\d{4}$/.test(form.ssn)) {
      newErrors.ssn = 'SSN should be 4 digits';
    }
    if (!/^[a-zA-Z0-9]{1,20}$/.test(form.confirmationNo)) {
      newErrors.confirmationNo = 'Confirmation number should be up to 20 alphanumeric characters';
    }
    if (!/^\d{8,11}$/.test(form.cardNo)) {
      newErrors.cardNo = 'Card number should be between 8 and 11 digits';
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
    setDirty({ ...dirty, [name]: true });
  };

  const handleBlur = (e) => {
    const { name } = e.target;
    setTouched({ ...touched, [name]: true });
    // validate();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // if (!validate()) {
    //   setSnackbarMessage('Please fill the details correctly.');
    //   setSnackbarOpen(true);
    //   return;
    // }

    const recaptchaValue = await recaptchaRef.current.executeAsync();

    if (recaptchaValue) {
      const formData = {
        ...form,
        recaptchaToken: recaptchaValue,
      };
      setIsLoading(true);
      try {
        const response = await fetch(
          "https://windev.mashvirtual.in/ZyncSmartSchedular/api/Auth/Login",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "Accept-Encoding": "gzip, deflate, br",
              Accept: "*/*",
            },
            body: JSON.stringify(formData),
          }
        );

        if (!response.ok) {
          if (response.status === 400) {
            const errorData = await response.json();
            setSnackbarMessage(errorData.message || "Validation Failed.");
            recaptchaRef.current.reset();
          } else {
            recaptchaRef.current.reset();
            throw new Error("Network response was not ok " + response.statusText);
          }
          // setTimeout(() => {
          //   window.location.reload();
          // }, 3000);
        } else {
          const data = await response.json();
          localStorage.setItem("current user", JSON.stringify(data));
          // login(data);
          console.log("Navigating to dashboard...");
          navigate("/dashboard");
        }
      } catch (error) {
        console.error("Error occurred catch:", error);
        recaptchaRef.current.reset();
      }
      // finally {
      //   console.error("Error occurred final:");
      //   recaptchaRef.current.reset();
      //   setIsLoading(false);
      //   setSnackbarOpen(true);
      // }
      setIsLoading(false);
      setSnackbarOpen(true);
    };

  }
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };
  return (
    <div>
      <header className="px-6 py-4 flex justify-between items-center border-b border-gray-200">
        <div className="flex items-center">
          <h1 className="text-2xl font-bold">Zync Smart Scheduler</h1>
        </div>
      </header>
      <div className="bg-[#F9E9DE] min-h-screen flex flex-col items-center p-4">
        <main className="w-full max-w-xl bg-white p-6 rounded-lg shadow-md">
          <form onSubmit={handleSubmit}>
            <h1 className="text-2xl font-bold mb-6 text-center ">SIGN IN</h1>
            <Box
              sx={{
                display: "grid",
                gridGap: 20,
              }}
            >
              <TextField
                label="Texas Card Number (DL, ID, EIC)"
                id="outlined-start-adornment"
                size="small"
                name="cardNo"
                value={form.cardNo}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.cardNo && Boolean(errors.cardNo)}
                helperText={touched.cardNo && errors.cardNo}
                sx={{ m: 1 }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <ConfirmationNumberOutlinedIcon
                        sx={{ color: "#1E73BE" }}
                      />
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                label="Confirmation Number"
                id="outlined-start-adornment"
                size="small"
                name="confirmationNo"
                value={form.confirmationNo}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.confirmationNo && Boolean(errors.confirmationNo)}
                helperText={touched.confirmationNo && errors.confirmationNo}
                sx={{ m: 1 }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <ConfirmationNumberOutlinedIcon
                        sx={{ color: "#1E73BE" }}
                      />
                    </InputAdornment>
                  ),
                }}
                required
              />
              <TextField
                label="First Name"
                id="outlined-start-adornment"
                size="small"
                name="firstName"
                value={form.firstName}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.firstName && Boolean(errors.firstName)}
                helperText={touched.firstName && errors.firstName}
                sx={{ m: 1 }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <BadgeOutlinedIcon sx={{ color: "#1E73BE" }} />
                    </InputAdornment>
                  ),
                }}
                required
              />
              <TextField
                label="Last Name"
                id="outlined-start-adornment"
                size="small"
                name="lastName"
                value={form.lastName}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.lastName && Boolean(errors.lastName)}
                helperText={touched.lastName && errors.lastName}
                sx={{ m: 1 }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <BadgeOutlinedIcon sx={{ color: "#1E73BE" }} />
                    </InputAdornment>
                  ),
                }}
                required
              />
              <TextField
                label="Date of Birth"
                type="date"
                id="outlined-start-adornment"
                size="small"
                name="dob"
                value={form.dob}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.dob && Boolean(errors.dob)}
                helperText={touched.dob && errors.dob}
                sx={{ m: 1 }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <CalendarMonthOutlinedIcon sx={{ color: "#1E73BE" }} />
                    </InputAdornment>
                  ),
                }}
                required
              />
              <TextField
                label="SSN"
                type="password"
                id="outlined-start-adornment"
                size="small"
                name="ssn"
                value={form.ssn}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.ssn && Boolean(errors.ssn)}
                helperText={touched.ssn && errors.ssn}
                sx={{ m: 1 }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <ConfirmationNumberOutlinedIcon
                        sx={{ color: "#1E73BE" }}
                      />
                    </InputAdornment>
                  ),
                }}
                required
              />
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey="6Ld9YnwqAAAAAHMnvIl03TiZOVbX-TIdN2BVK74j" // Replace with your reCAPTCHA site key
                size="invisible"
              />
              <div className="flex flex-col items-center mt-2 justify-center">
                <button type="submit" className="group relative flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md bg-[#1E73BE] text-white" disabled={isLoading}>
                  {isLoading ? 'Signing in...' : 'Sign In'}
                </button>

              </div>
            </Box>
          </form>
        </main>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="error"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default LoginPage;
