import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Snackbar, Alert } from "@mui/material";
import EventBusyOutlinedIcon from "@mui/icons-material/EventBusyOutlined";

const DashboardPage = () => {
  const navigate = useNavigate();
  const [appointments, setAppointments] = useState([]);
  const [fetchError, setFetchError] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success"); // success | error

  const [isServiceActivated, setIsServiceActivated] = useState(false);

  const goToAboutUs = () => {
    navigate("/info");
  };


  const currentUser = localStorage.getItem("current user");
  const token = currentUser ? JSON.parse(currentUser).token : null;

  const StopSearching = () => {
    const url =
      "https://windev.mashvirtual.in/ZyncSmartSchedular/api/Appointment/StopService";
    const userAgent = navigator.userAgent;

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Accept-Encoding": "gzip, deflate, br",
        Accept: "*/*",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ UserAgent: userAgent }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        // console.log("Success:", data);
        setSnackbarMessage("Service stopped successfully!");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
      })
      .catch((error) => {
        // console.error("Error:", error);
        console.error("Error:", error);
        setSnackbarMessage("Failed to stop the service. Please try again.");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      });
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };


  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchAppointments = async () => {
      try {
        const response = await fetch(
          "https://windev.mashvirtual.in/ZyncSmartSchedular/api/Appointment/GetUserAppointment",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "Accept-Encoding": "gzip, deflate, br",
              Accept: "*/*",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const data = await response.json();

        if (data?.isServiceActivated) {
          setIsServiceActivated(data.isServiceActivated); // Update state with service status
        }


        if (Array.isArray(data.data) && data.data.length > 0) {
          setAppointments(data.data);
          setFetchError(false);
        } else {
          setFetchError(true);
        }
      } catch (error) {
        console.error("Error fetching appointments:", error);
        setFetchError(true);
      }
    };

    fetchAppointments();
  }, [token]);


  const handleLogout = () => {
    localStorage.removeItem("current user");
    navigate("/login");
    window.location.reload();
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const months = [
        "January", "February", "March", "April", "May", "June", 
        "July", "August", "September", "October", "November", "December"
    ];
    const day = String(date.getDate()).padStart(2, "0");
    const month = months[date.getMonth()];
    const year = date.getFullYear();
    return `${day} ${month}, ${year}`;
};

  return (
    <div>
      <header className="px-6 py-4 flex justify-between items-center border-b border-gray-200">
        <div className="flex items-center">
          <h1 className="text-2xl font-bold">Zync Smart Scheduler</h1>
        </div>
        <button
          className="bg-[#1E73BE] text-white px-4 py-2 rounded"
          onClick={handleLogout}
        >
          Logout
        </button>
      </header>
      <div className="bg-gradient-to-br from-[#EDEDE9] to-[#F5EBE0] min-h-screen flex flex-col items-center p-4">
        <main className="w-full max-w-4xl bg-white p-5 rounded-lg shadow-2xl ">
          {fetchError ? (
            //Appointment Not Found
            <div className="min-h-screen bg-white flex items-center justify-center p-4">
              <div className="text-center max-w-md w-full">
                <div className="mb-4x">
                  <EventBusyOutlinedIcon
                    sx={{ color: "#1E73BE", fontSize: 80 }}
                  />
                </div>
                <h1 className="text-center text-4xl font-bold text-gray-900 mb-6 font-roboto m-auto">
                  No Appointment Found
                </h1>
                <p className="text-gray-600 mb-10 font-roboto text-lg leading-relaxed">
                  We couldn't locate the appointment you're looking for.
                </p>
                {/* <button className="bg-gradient-to-r from-indigo-500 to-blue-500 text-white font-semibold py-4 px-8 rounded-xl transition-all duration-200 hover:shadow-lg hover:opacity-90 transform hover:-translate-y-1 font-roboto text-lg">
                  Book Appointment
                </button> */}
                <div className="flex flex-col items-center mt-2">
                  <button
                    className="bg-[#1E73BE] text-white font-semibold py-4 px-8 rounded-xl transition-all duration-200 hover:shadow-lg hover:opacity-90 transform hover:-translate-y-1 font-roboto text-lg"
                    onClick={goToAboutUs}
                  >
                    Reschedule
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div className="w-full max-w-4xl mx-auto bg-white rounded-lg ">
              <div className="relative overflow-x-auto">
                <table className="w-full bg-red px-3 py-1 rounded-full">
                  <thead>
                    <tr className="border bg-[#1E73BE] ">
                      <th className="text-left py-4 px-6 font-medium text-white">
                        Date
                      </th>
                      <th className="text-left py-4 px-6  font-medium text-white">
                        Confirmation Number
                      </th>
                      <th className="text-left py-4 px-6  font-medium text-white">
                        Time
                      </th>
                      <th className="text-left py-4 px-6  font-medium text-white">
                        Change No.
                      </th>
                      <th className="text-left py-4 px-6  font-medium text-white">
                        Remaining Days
                      </th>
                      <th className="text-left py-4 px-6  font-medium text-white">
                        Cancelled
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {appointments.map((appointment) => (
                      <tr
                        key={appointment.id}
                        className="border-b last:border-b-0"
                      >
                        <td className="py-4 px-6">
                          <div className="flex items-center gap-3">
                            <div className="text-sm text-gray-500">
                              {formatDate(appointment.bookingDate)}
                            </div>
                          </div>
                        </td>
                        <td className="py-4 px-6">
                          <div className="text-sm text-gray-500">
                            {appointment.confirmationNo}
                          </div>
                        </td>
                        <td className="py-4 px-6">
                          <div className="text-sm text-gray-500">
                            {new Date(appointment.confirmationDate).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                          </div>
                        </td>
                        <td className="py-4 px-6">
                          <div className="text-sm text-gray-500">
                            {appointment.changeNo}
                          </div>
                        </td>
                        <td className="py-4 px-6">
                          <div className="text-sm text-gray-500">
                            {appointment.remainingDays}
                          </div>
                        </td>
                        {/* <td className="py-4 px-6">
                          <div className="text-sm text-gray-500">
                            {appointment.isCanceled}
                          </div>
                        </td> */}
                        <td className="py-4 px-6">
                          {/* <span
                            className={`px-3 py-1 rounded-full text-sm ${getStatusColor(
                              appointment.isCanceled
                            )}`}
                          > */}
                          <div className="text-sm text-gray-500">
                            {appointment.isCanceled ? "Yes" : "No"}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="flex flex-row items-center mt-2 justify-center">
                <div className="mr-1">
                  <button
                    className={`bg-[#1E73BE] text-white px-4 py-2 rounded shadow-lg shadow-blue-500/50 transition-opacity duration-300 ${isServiceActivated ? "opacity-50 cursor-not-allowed" : "hover:opacity-90"
                      }`}
                    onClick={goToAboutUs}
                    disabled={isServiceActivated} // Disable the button if service is active
                  >
                    Reschedule
                  </button>
                </div>

                {/* Stop Button */}
                <div>
                  <button
                    className={`bg-[#1E73BE] text-white px-4 py-2 rounded shadow-lg shadow-blue-500/50 transition-opacity duration-300 ${isServiceActivated ? "hover:opacity-90" : "opacity-50 cursor-not-allowed"
                      }`}
                    onClick={StopSearching}
                    disabled={!isServiceActivated} // Disable when service is NOT active
                  >
                    Stop
                  </button>
                  {/* </div> */}
                </div>
              </div>
            </div>
          )}
        </main>
      </div >
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div >
  );
};

export default DashboardPage;
